import React, { useEffect } from 'react'
import type { FC } from 'react'
import type { PageProps as GatsbyPageProps } from 'gatsby'
import { graphql } from 'gatsby'
import Layout from 'src/components/Layout'
import Seo from 'src/views/institucional/seja-representante/cadastro/Seo'
import { Flex } from 'theme-ui'

import type { CadastroRepresentanteAvonQueryQuery } from './__generated__/CadastroRepresentanteAvonQuery.graphql'

export type Props = GatsbyPageProps<CadastroRepresentanteAvonQueryQuery>

const Page: FC<Props> = (props) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.location.replace(
        'https://accounts.natura-avon.com/queroserconsultora/?utm_source=avon&utm_medium=site&utm_term=cadastro_sejarep_redirect&utm_content=perene&utm_campaign=avon_site_cadastro_sejarep_redirect_perene'
      )
    }
  }, [])

  return (
    <Layout>
      <Seo {...props} />

      <Flex
        sx={{
          padding: '5rem 0',
          fontSize: '2.5rem',
          fontWeight: 'bold',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        Aguarde um momento, <br /> estamos te levando para o cadastro!
      </Flex>
    </Layout>
  )
}

export const query = graphql`
  query CadastroRepresentanteAvonQuery {
    cmsInstitutionalPage(name: { eq: "Cadastro Representante Avon" }) {
      sections {
        data
        name
      }
      seo {
        siteMetadataWithSlug {
          description
          slug
          title
          titleTemplate
        }
      }
    }
    vtex {
      facets {
        breadcrumb {
          href
          name
        }
      }
    }
  }
`

export default Page
